@import './assets/style/_overrides.scss';

body {
	background-color: $nbPrimaryBg;
	color: $nbPrimaryBgFontColor;
}

.custom-skeleton ul {
    list-style: none;
}

.p-tooltip-active {
	z-index: 4000000!important;
}

.app-header-margin {
	margin-bottom: 2rem!important;
}

.app-form-margin {
	margin-bottom: 2rem;
}

.app-form-margin-up {
	margin-top: 2rem;
}

.app-row-margin {
	margin-bottom: 1rem;
}

.app-row-margin-up {
	margin-top: 1rem;
}

.app-small-margin-bottom {
	margin-bottom: 0.5rem;
}

.app-modal-link-button {
	text-decoration: none;
	width: 100%;
}

.app-form-contact-item {
	margin-bottom: 1rem;

	&:hover {
		background-color: rgba(211, 47, 47, 0.04);
	}

	&.app-contact-is-confirmed {
		.p-button-plain {
			.pi {
				color: #689F38;
			}
		}
	}

	&.app-contact-is-default {
		.p-button-label {
			font-weight: 600;
		}
	}

	.p-button-plain {
		flex-grow: 1;
		text-align: left;
	}
	.p-button-danger {
		flex-grow: 0!important;
		flex-basis: auto!important;
		&:hover {
			background-color: transparent;
		}
	}
}

.app-header-panel {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1rem;
}

.app-card {
	.p-card-body {
		padding: 0rem;
		.p-card-content {
			padding: 0rem;
		}
	}
}

.app-contact-list {
	padding: 1rem;

	.app-contact-item {
		padding: 1rem;
		border: 1px solid #dee2e6;
		border-radius: 3px;
		margin-bottom: 1rem;
	}
}

.app-select-fieldset {
	margin-bottom: 1rem;
	.p-fieldset-legend {
		padding: 0;
		border: 0;
	}

	.p-fieldset-content {
		padding: 1rem 0 0;
	}
}

.app-unseen {
	border-left: 4px solid #6a1b9a;
}

.app-contain-list {
	padding: 1rem;

	.app-contain-item {
		//background: #e9ecef;
		color: #6c757d;
		border-color: transparent;
		border-radius: 3px;
		margin-bottom: 1rem;

		&:hover {
			background-color: rgba(211, 47, 47, 0.04);
		}

		.p-button-plain {
			flex-grow: 1;
			text-align: left;
			padding: 0.5rem;
			text-decoration: none;
			color: inherit;
			background-color: transparent;

			&:hover {
				background: #e9ecef;
				color: #6c757d;
			}
		}
		.p-button-danger {
			flex-grow: 0!important;
			flex-basis: auto!important;
			&:hover {
				background-color: transparent;
			}
		}
	}
}

.p-dialog-mask {
	z-index: 10000000 !important;
	.p-dialog {
		max-width: 400px;
	}
}

.app-datatable-tabview {
	.p-tabview-panels {
		padding: 0;
	}
}

.p-datatable {
	&.app-datatable-noheader {
		.p-datatable-thead tr:first-child {
			display: none;
		}
	}
}

.app-datatable-tree {
	&.app-datatable-noheader {
		.p-treetable-thead tr:first-child {
			display: none;
		}
	}
	.p-treetable-thead {
		.p-dropdown {
			width: 100%;
		}
		.p-datepicker {
			min-width: 25rem;
		}
	}
}

@media screen and (max-width: 960px) {
	.app-datatable-tree {
		.p-treetable-thead > tr > th,
		.p-treetable-tfoot > tr > td {
			display: none !important;
		}
	}
}

.status-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-canceled, &.status-no  {
		background-color: #ffcdd2;
		color: #c63737;
	}
	&.status-progress {
		background-color: #b3e5fc;
		color: #23547b;
	}
	&.status-wait, &.status-partial {
		background-color: #feedaf;
		color: #8a5340;
	}
	&.status-new {
		background-color: #eccfff;
		color: #694382;
	}
	&.status-continue, &.status-await {
		background-color: #ffd8b2;
		color: #805b36;
	}
	&.status-success, &.status-complete {
		background-color: #c8e6c9;
		color: #256029;
	}
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
	margin: 0;
	padding: 0.5rem 1rem;
	border: 0 none;
	color: #495057;
	background: transparent;
	transition: box-shadow 0.2s;
	border-radius: 0;
}

.app-field-required {
	border: 1px solid #2196F3;
}

.app-compact-field {
	margin-bottom: 0;
}

.app-status-editor {
	.p-chips-token {
		padding: 0!important;
		margin-right: 0.5rem!important;

		.status-badge {
			padding: 0.5rem 2.5rem 0.5rem 0.5rem;
			margin-right: -2rem;
		}

		.p-chips-token-icon {
			margin-left: 0;
			margin-right: 0.5rem;
		}
	}

	.p-contextmenu {
		left: initial!important;
		top: initial!important;
		padding: 0;

		.status-badge {
			border: 2px solid rgba(0,0,0,0);
			padding: 0;
			margin: 0;

			.p-menuitem-link {
				padding: 0.5rem 1rem;
			}

			&:hover .p-menuitem-link, .p-menuitem-link:hover, &.p-menuitem-active .p-menuitem-link {
				background: none;
			}

			&:hover {
				border: 2px solid rgba(0,0,0,0.5);
			}
		}
	}
}

.app-nested {
	sup, sup i, strike {
		font-size: 0.8rem;
	}
	strike {
		margin-left: 1rem;
	}
	&.app-nested-0 {
		padding-left: 0rem;
		font-size: 1.2rem;
	}
	&.app-nested-1 {
		padding-left: 1rem;
		font-size: 1.1rem;
	}
	&.app-nested-2 {
		padding-left: 2rem;
		font-size: 1rem;
	}
	&.app-nested-3 {
		padding-left: 3rem;
		font-size: 0.9rem;
	}
	&.app-nested-4 {
		padding-left: 4rem;
		font-size: 0.8rem;
	}
}

.app-editable-products {

}

.app-product-table {
	.app-products-remove {
		opacity: 0;
	}

	.p-datatable-tbody > tr:hover {
		.app-products-remove {
			opacity: 1;
		}
	}

	.p-editable-column {
		height: 2rem;

		.app-input-number {
			.p-inputnumber {
				flex-direction: row-reverse;
				margin-left: -2.5rem;

				input {
					padding-right: 0;
					border-right: 0;
					text-align: right;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

				.p-inputnumber-button-group {
					.p-inputnumber-button-up {
						border-top-left-radius: 3px;
						border-bottom-left-radius: 0px;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						width: 2rem;

						.p-button-icon {
							font-size: 0.6rem;
						}
					}
					.p-inputnumber-button-down {
						border-top-left-radius: 0px;
						border-bottom-left-radius: 3px;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						width: 2rem;
						.p-button-icon {
							font-size: 0.6rem;
						}
					}
				}
			}
			.p-dropdown {
				border-left: 0;
				width: 4rem;

				input {
					width: 1.5rem;
				}

				.p-dropdown-trigger {
					width: 1.5rem;
				}
			}
		}
	}
}

.app-price {
	.app-price-final {
		font-weight: bold;
	}

	.app-price-unit {
		opacity: 0.8;
	}

	&.app-price-large {
		font-size: 1.4rem;
		.app-price-unit {
			font-size: 1rem;
		}
	}

	.app-price-margin {
		text-align: right;
		strong {
			font-size: 1rem;
			color: #5e8f32;
		}
	}
}

.p-fluid {
	margin-top: 0;
}

.p-datatable {
	margin-bottom: 0.5rem;
}

.app-products-result {
	padding: 0.5rem 0.5rem;
	background: #f8f9fa;
	border: 1px solid #e9ecef;
	border-width: 1px 0 1px 0;
	margin: 0;
}

.app-table-nohead {
	thead {
		display: none;
	}
}

.app-deal-listitem {
	display: flex;
	flex-direction: column;
	.app-price-final {
		font-weight: 500;
		padding: 0.25rem 0.5rem;
	}
}

.app-pane-products {
	.p-datatable {
		margin: 0;
	}
	.p-datatable-tbody {
		tr > td {
			padding: 0.25rem 0.5rem!important;
		}
	}

	.app-nested {
		&.app-nested-0 {
			padding-left: 0rem;
			font-size: 1rem;
		}
		&.app-nested-1 {
			padding-left: 0.5rem;
			font-size: 0.9rem;
		}
		&.app-nested-2 {
			padding-left: 1rem;
			font-size: 0.8rem;
		}
		&.app-nested-3 {
			padding-left: 1.5rem;
			font-size: 0.7rem;
		}
		&.app-nested-4 {
			padding-left: 2rem;
			font-size: 0.6rem;
		}
	}

	.p-accordion-content {
		padding: 0;

		.p-autocomplete-panel {
			left: auto!important;
			max-width: 16rem;
			li {
				white-space: break-spaces;
			}
		}

		.p-tieredmenu {
			width: 100%;

			.p-submenu-list {
				display: block;
				position: initial;
				box-shadow: none;
				padding: 0;
			}

			.p-submenu-icon {
				display: none;
			}
		}

		/*.p-panelmenu-header {
			&.p-highlight {

			}

			.p-panelmenu-header-link {
				border: 0;
				background: none;
			}
		}

		.p-panelmenu-content {
			border: 0;
		}*/
	}
}

.app-deal-product-row {
	font-size: 0.9rem;
	border-bottom: 1px solid #e9ecef;

	td {
		padding: 0rem!important;
		border: none!important;
	}

	td:first-child {
		display: inline-flex;
		align-items: center;

		.app-deal-product-name {
			//position: relative;
			white-space: nowrap;
			//padding: 0.5rem 0 0.5rem 1rem;
		}
	}

	.app-price-final {
		padding-left: 1.25rem;
		font-weight: 400;
	}

	.app-deal-listitem {
		padding: 0 1rem;

		.status-badge {
			font-size: 0.7rem;
		}
	}
}

.app-category-tree-select {
	.p-autocomplete-token {
		flex-flow: row-reverse;
		background: transparent!important;
		padding-left: 0!important;

		.p-autocomplete-token-icon {
			margin-right: 1rem;
		}

		.pi-times-circle:before {
			content: "\e932";
		}
	}

	.p-autocomplete-token:first-child {
		.p-autocomplete-token-icon {
			display: none;
		}
	}
}

.buttons {
	.p-button{
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
	}
	.p-fileupload {
		margin-right: 1rem;
	}
}

.app-file-attachments {
	display: flex;
	flex-direction: column;

	.p-fileupload-content {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.p-scrollpanel {
		margin: 0!important;
		background: #ffffff;
		border: 1px solid #dee2e6;
		color: #495057;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		border-top: none;

		&:hover .p-scrollpanel-bar {
			background-color: #2196F3;
		}
	}

	.app-attachments {
		border: 1px solid #dee2e6;
		border-top-width: 0;
		.app-attachment-item {
			display: flex;

			.p-button {
				flex-grow: 1;
				text-align: left;
			}

			.p-button-danger {
				flex-grow: 0;
				visibility: hidden;
			}

			&:hover {
				.p-button-danger {
					visibility: visible;
				}
			}

			.p-button:disabled {
				opacity: 1;
			}

			.p-dropdown {
				border: 0;
			}
		}
	}

	.app-attachments-preview {
		flex-wrap: wrap;
		.app-attachment-item {
			background-repeat: no-repeat;
			height: 200px;
			background-size: contain;
			width: 200px;
			align-items: flex-end;
			display: inline-flex;
			margin: 0.5rem;
			border: 1px dashed #dee2e6;

			.attachment-buttons {
				display: flex;
				justify-content: space-between;
				width: calc(100% + 2px);
				background-color: #dee2e6;
				margin: 0 -1px -1px;

				.p-button-label {
					white-space: nowrap;
				}
			}
		}
	}
}

.app-panel {
	margin-bottom: 1rem;
	h2, h3 {
		margin-bottom: 1rem;
	}
}

.ace_editor * {
	font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important;
}

.app-code-container {
	display: flex;

	.ace_editor {

	}
	.app-code-additionals {
		padding-left: 1rem;
		flex-grow: 1;
	}
}

.app-datatable-headless {
	.p-datatable-thead {
		display: none;
	}
	.p-datatable-tbody tr:last-child td {
		border: none;
	}
}

.p-sidebar {
	.p-sidebar-header {
		right: 0!important;
		top: 0!important;
		padding: 0.5rem!important;
	}
}

.p-dropdown-panel {
	z-index: 100010100!important;
}

.nb24-oauth {
	display: flex;
	justify-content: center;
	color: $nbPrimaryBgFontColor!important;

	.icon {
		position: relative;
		top: -2px;
		margin-left: 0.5rem;
		font-size: 1rem;
	}

	&.nb24-oauth-yandex {
		border-color: #fc3f1d;
		.icon {
			color: #fc3f1d;
		}

		&:hover {
			border-color: #fc3f1d!important;
			background-color: rgba(252, 63, 29, 0.04)!important;
		}
	}
}

.nb24-button-primary {
	background-color: $nbPrimaryColor;
	border-color: $nbPrimaryColor;
	box-shadow: inset 0 5px 6px -3px rgba(0, 0, 0, 0.2), inset 0 -5px 14px 1px rgba(0, 0, 0, 0.14), inset 0 -5px 18px 3px rgba(0, 0, 0, 0.12);

	&:hover {
		background-color: $nbSecondaryColor;
	}
}

.nb24-bg-1 {
	background-image: url(./assets/image/bg/nebula/1.jpg);
}
.nb24-bg-2 {
	background-image: url(./assets/image/bg/nebula/2.jpg);
}
.nb24-bg-3 {
	background-image: url(./assets/image/bg/nebula/3.jpg);
}

.autocomplete-fix {
	z-index: 10010040!important;
	margin-left: 1rem!important;
}

.content-section-form {
	.p-card-body {
		padding-top: 0;
		.p-card-content {
			padding-top: 0;
		}
	}
	.content-section-header {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

.content-preview-state {
	.p-col {
		text-align: center;
	}
}

.column-with-checkbox {
	display: flex;
	align-items: center;

	.p-checkbox {
		margin-right: 0.5rem;
	}
}

.fieldset-with-files {
	.p-toggleable-content {
		margin: 0 -29px -27px;
	}
}

.fieldset-with-array {
	.p-fieldset-content {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}
main {
	background-color: #e0e0e0;
}

.app-dropdown-gallery {

}

.app-dropdown-gallery-panel {
	max-width: 80%;
	.p-dropdown-items {
		display: flex;
		flex-wrap: wrap;

		.p-dropdown-item {
			padding: 0.5rem;
			div {
				display: flex;
				flex-direction: column;
				width: 150px;
				border: 1px solid #dee2e6;

				img {
					width: 150px;
					height: 150px;
					object-fit: cover;
					object-position: center;
				}

				span {
					background-color: white;
					padding: 0.25rem;
					border-top: 1px solid #dee2e6;
				}
			}
		}
	}
}

.content-status {
	.p-message {
		.p-message-summary {
			margin-right: 1rem;
			font-weight: 600;
		}
		.p-message-detail {

		}
	}
}

.content-url-column {
	display: flex;
	gap: 0.5rem;
}

.nb24-color-picker {
	.p-colorpicker {
		padding: 0;
		position: relative;
		.p-colorpicker-preview {
			background: transparent !important;
			display: block;
			height: calc(100% + 2px);
			width: calc(100% + 2px);
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			position: absolute;
			top: -1px;
			border-left: 0;
		}
	}
}

.app-single-attachment {
	max-height: 39px;
	.p-fileupload {
		display: flex;
		flex-direction: row-reverse;
		width: 100%;
	}

	.p-fileupload-buttonbar {
		padding: 0;
		border: 0;
		background: transparent;
		display: flex;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		.p-button {
			margin: 0;
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}

	.p-fileupload-content {
		display: flex;
		flex-grow: 1;
		padding: 0;
		border: 0;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;

		img {
			height: 39px;
		}

		.p-inputtext {
			flex-grow: 1;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}
	}

	.p-fileupload-files {
		flex-grow: 1;
		padding: 0 1rem;
	}

	.p-fileupload .p-fileupload-row > div {
		padding: 0;
		display: flex;
	}

	.p-fileupload-row > div:last-child {
		justify-content: flex-end;
	}

	.p-fileupload-content > .p-progressbar {

	}
}