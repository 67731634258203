$topLineHeight: 50px;
$sidebarWidth: 15rem;
$sidebarCompactWidth: 3.25rem;
$paneWidth: 20rem;
$modalPaneWidth: 60rem;

$nbPrimaryColor: #6a1b9a;
$nbSecondaryColor: #1976d2;
$nbPrimaryBg: #fafafa;
$nbPrimaryBgFontColor: #212121;
$nbSecondaryBg: #212121;
$nbSecondaryBgFontColor: #ffffff;
$nbTopContentPadding: 2rem;

@mixin flex-centered-row {
	align-items: center;
	display: flex;
}

@mixin flex-stretch-column {
	display: flex;
    flex-direction: column;
    justify-content: stretch;
}